<template>
<Alert :isActive="isError" v-if="isError">{{errorMessage}}</Alert>
<Loading :isShow="isLoading" v-if="!isError">
  <Navigation></Navigation>
  <div class="theme-container" v-if="!isLoading">
    <PageTitle
      :title="content['page-title']"
      :imgSrc="images['header4.svg']"
    ></PageTitle>
      <div class="sectionContainer pretext">
        <div>{{content['header-pretext']}}</div>
      </div>
      <div class="sectionContainer"> 
        <h1 class="main-title"> {{content['header-title']}} </h1>
        <p>{{content['header-description']}}</p>
      </div>
      <div class="partnershipContainer">
        <h1> {{content['section-1-title']}} </h1>
        <p> <span style="font-weight:600"> Select a year </span> to view project starts and examples in the AbbVie - Slalom partnership. </p>
        <div class="flexContainer">
        <div class="flex-12">
          <Timeline :timelineEvents="timelineEvents" :ascending="false" :yearCutoff="2016" />
        </div>
        </div>
      </div>
      <div class="sectionContainer">
        <div style="margin-bottom: 35px;">
          <SurveyResultsCard
            :title="content['section-2-title']"
            :paragraph="content['section-2-description-1']"
          />
        </div>
        <div>
          <SurveyResultsCard
          :cards="[
            {
              imgSrc: `${images['collab_icon.svg']}`,
              tileTitle: content['section-2-subsection-1-title-1'],
              tileParagraph: content['section-2-subsection-1-description-1'],
            },
            {
              imgSrc: `${images['innovation.svg']}`,
              tileTitle: content['section-2-subsection-2-title-1'],
              tileParagraph: content['section-2-subsection-2-description-1'],
            },

            {
              imgSrc: `${images['growthicon.svg']}`,
              tileTitle: content['section-2-subsection-3-title-1'],
              tileParagraph: content['section-2-subsection-2-description-1'],
            },
          ]"
        />
        </div>
      </div>
      
      <div class="sectionContainer inv">
        <h1 class="title-measuring">{{ content["section-3-title"] }}</h1>
        <h3 class="paragraph">{{ content["section-3-subtitle"] }}</h3>
        <div class="flex-container">
          <div class="flex-6">
            <div class="grid measuring-card-set">
              <div class="grid-row">
                <div class="flex-6" v-for="card in expectedCards">
                  <div class="card-wrapper">
                    <MeasuringCard :card="card" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex-6">
            <div class="grid measuring-card-set">
              <div class="grid-row">
                <div class="flex-6" v-for="card in transformationCards">
                  <div class="card-wrapper">
                    <MeasuringCard :card="card" />
                  </div>
                </div>
                <div class="flex-12 graph-card">
                  <div class="card-wrapper">
                    <div class="grid-cell">
                      <div class="grid-content">
                        <h1 class="cardHeading">
                          {{ content["section-3-card-11-title"] }}
                        </h1>
                        <div class="graph-wrapper">
                          <div class="leftSide-graph">                          
                            <div class="subtitle2">{{ content['section-3-card-11-great-value-2'] }}</div>
                            <div class="number">
                              {{ content["section-3-card-11-newvalue"]
                              }}<span><img
                                  class="triangle-img"
                                  src="@/assets/images/survey/triangle.png"
                                  alt=""/></span>
                            </div>
                            <div class="subtitle2">
                              {{ content["section-3-card-11-oldvalue"] }}
                            </div>
                            <div class="subtitleYear">{{ content['section-3-card-11-year'] }}</div>
                          </div>
                          <div class="rightSide-graph">
                            <ul style="list-style: none" class="graph">
                              <li>
                                <span class="circle circle-color-one"></span
                                ><span class="graph-rating">Great:</span
                                ><span class="percentage">{{ content['section-3-card-11-great-value'] }}</span>
                              </li>
                              <li>
                                <span class="circle circle-color-two"></span
                                ><span class="graph-rating">Good:</span
                                ><span class="percentage">{{ content['section-3-card-11-good-value'] }}</span>
                              </li>
                              <li>
                                <span class="circle circle-color-three"></span
                                ><span class="graph-rating">Fair:</span
                                ><span class="percentage">{{ content['section-3-card-11-fair-value'] }}</span>
                              </li>
                              <li>
                                <span class="circle circle-color-four"></span
                                ><span class="graph-rating">Poor:</span
                                ><span class="percentage">{{ content['section-3-card-11-poor-value'] }}</span>
                              </li>
                            </ul>
                            <div class="bar">
                              <div class="bar-inner blue"></div>
                              <div class="bar-inner green"></div>
                              <div class="bar-inner yellow"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </Loading>
  <BottomNavigator :order='4'/>
</template>

<script>
import BottomNavigator from '../components/BottomNavigator.vue'
import Loading from "@/components/Loading.vue";
import Alert from "@/components/Alert.vue"
import PageContainer from "@/components/PageContainer.vue";
import Navigation from "@/components/Navigation.vue";
import PageTitle from "@/components/PageTitle.vue";
import Timeline from "@/components/Timeline.vue";
import api from '@/services/api.js';
import SurveyResultsCard from '@/components/SurveyResultsCard.vue'
import MeasuringCard from '@/components/MeasuringCard.vue'

export default {
  data: () => ({
		content: {},
		images: [],
		isLoading: false,
		isError: false,
		errorMessage: '',
    timelineEvents: [],
    transformationCards: [],
    expectedCards: [],
	}),
	methods: {
		async getData() {
			this.isError = false;
			this.isLoading = true;
			try {
				const { data } = await api.getContent('partnership');
				this.content = data.content;
        for (let i = 1; i < 100; i++) {
          if (`event-${i}` in this.content) {
            var event = this.content[`event-${i}`]
            this.timelineEvents.push({date:event.date, imgSrc:event.imgSrc, title: event.title, desc1: event.desc1, desc2: event.desc2, desc3: event.desc3, desc4: event.desc4, desc5: event.desc5, desc6: event.desc6})
          } else {
            break
          }
        }
        this.expectedCards = [
          {
            title: this.content["section-3-card-13-title"],
            subtitle: this.content["section-3-card-13-newvalue"],
            subtitle2: this.content["section-3-card-13-oldvalue"],
          },
          {
            title: this.content["section-3-card-12-title"],
            subtitle: this.content["section-3-card-12-newvalue"],
            subtitle2: this.content["section-3-card-12-oldvalue"],
          },
          {
            title: this.content["section-3-card-1-title"],
            subtitle: this.content["section-3-card-1-newvalue"],
            subtitle2: this.content["section-3-card-1-oldvalue"],
          },          
          {
            title: this.content["section-3-card-2-title"],
            subtitle: this.content["section-3-card-2-newvalue"],
            subtitle2: this.content["section-3-card-2-oldvalue"],
          },
          {
            title: this.content["section-3-card-3-title"],
            subtitle: this.content["section-3-card-3-newvalue"],
            subtitle2: this.content["section-3-card-3-oldvalue"],
          },
          {
            title: this.content["section-3-card-4-title"],
            subtitle: this.content["section-3-card-4-newvalue"],
            subtitle2: this.content["section-3-card-4-oldvalue"],
          },
          {
            title: this.content["section-3-card-5-title"],
            subtitle: this.content["section-3-card-5-newvalue"],
            subtitle2: this.content["section-3-card-5-oldvalue"],
          },
          {
            title: this.content["section-3-card-6-title"],
            subtitle: this.content["section-3-card-6-newvalue"],
            subtitle2: this.content["section-3-card-6-oldvalue"],
          },
        ];
        this.transformationCards = [
          {
            title: this.content["section-3-card-7-title"],
            subtitle: this.content["section-3-card-7-newvalue"],
            subtitle2: this.content["section-3-card-7-oldvalue"],
          },
          {
            title: this.content["section-3-card-8-title"],
            subtitle: this.content["section-3-card-8-newvalue"],
            subtitle2: this.content["section-3-card-8-oldvalue"],
          },
          {
            title: this.content["section-3-card-9-title"],
            subtitle: this.content["section-3-card-9-newvalue"],
            subtitle2: this.content["section-3-card-9-oldvalue"],
          },
          {
            title: this.content["section-3-card-10-title"],
            subtitle: this.content["section-3-card-10-newvalue"],
            subtitle2: this.content["section-3-card-10-oldvalue"],
          },
        ];
        console.log(data.content);
				this.images = data.images;
			} catch (err) {
				this.isError = true;
				this.errorMessage = err.message;
			} finally {
				this.isLoading = false;
			}
		},
	},

	async beforeRouteUpdate(to, from, next) {
		await this.getData();
		next();
	},

	async created() {
		await this.getData();
	},

  components: {
    BottomNavigator,
    Loading,
    PageContainer,
    Timeline,
    Navigation,
    PageTitle,
    Alert,
    SurveyResultsCard,
    MeasuringCard,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/mixins";

.theme-container {
  @include relative;
  @include margin(0 0 10 0);
  :deep(.page-title) {
    .title-container{
      @include bg-color( #082A60);
    }
  }
}

.partnershipContainer{
  @include bg-color(takeda-light-grey);
  @include padding(8);
  @include responsive(phone) {
    @include padding(4 1 4 1);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
}

.sectionContainer{
  &.pretext{
    padding-bottom:0px;
  }
  @include bg-color(takeda-white);
  @include padding(8);
  @include responsive(phone) {
    @include padding(4 1 4 1);
  }
  @include responsive(tablet) {
    @include padding(5 5 5 5);
  }
}

.inv {
  @include bg-color(takeda-light-grey);
}

.main-title {
  @include color(takeda-azuki-maroon);
  font-size: 48px;
  @include responsive(phone) {
    font-size: 38px;
  }
}

.leftSide-graph,
.rightSide-graph {
}
.rightSide-graph {
  min-height: 100px;
  justify-content:left;
}
.graph-wrapper{
  display:flex;
  justify-content: space-around;
}
.graph {
  display: inline-block;
  margin-bottom:0px;
  padding-left:0px;
}

.bar {
  display: inline-block;
  width: 20px;
  height: 85px;
  border-radius: 2px;
  margin-left: 25px;
  overflow: hidden;
}

.bar-inner {
  width: 100%;
}

.yellow {
  @include bg-color(#3D81FC);
  height: 10%;
}

.blue {
  @include bg-color(#0C62FB);
  height: 55%;
}

.green {
  @include bg-color(#3D81FC);
  height: 35%;
}

.circle {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  display: inline-block;
  margin-right: 10px;
}

.square {
  width: 15px;
  display: inline-block;
  margin-left: 40px;
}

.circle-color-one {
  background-color: #0C62FB;
}

.circle-color-two {
  background-color: #3D81FC;
;
}

.circle-color-three {
  background-color: #6DA1FD;
;
}

.circle-color-four {
  background-color: #9EC0FD;
;
}

.graph-rating {
  margin-right: 30px;
}

.measuring-card-set{
  padding-left: 1em;
  padding-right:1em;
  @include responsive(phone){
      padding-left: 0;
      padding-right:0;
  }
  @include responsive(tablet){
    .flex-6{
      flex: 0 6 50%;
    }
  }
}
.grid {
  display: flex;
  .grid-row{
    display:flex;
    flex-wrap: wrap;
      justify-content: center;
      .card-wrapper{
        padding:15px;
          @include responsive(phone){
            padding-left: 0;
            padding-right:0;
        }
      }
      .grid-cell {
        @include bg-color(takeda-white);
        border-radius: 8px 8px 48px 8px;
        box-shadow: 0px 4px 8px rgba(208, 215, 217, 0.4);
        overflow: hidden;
        position: relative;
        padding: 20px;
      }
  }
}

.grid-content {
  padding-top: 10px;
  padding-bottom: 20px;
}

.percentage {
  float: right;
  @include color(takeda-medium-gray);
  font-size: 16px;
  font-weight: bold;
}

.cardHeading {
  @include color(takeda-dark-gray);
  font-size: 16px;
  height: 50px;
}

.title-measuring {
  @include color(takeda-dark-maroon);
  padding-bottom: 30px;
  font-size: 48px;
}

.subHeader {
  padding-left: 35px;
  @include color(takeda-sorairo-blue);
  font-weight: 600;
  @include responsive(phone){
    padding-left: 10px;
  }
}

.subtitleYear {
  @include color(takeda-medium-gray);
  font-size: 16px;
}

.subtitle2 {
  @include color(takeda-medium-gray);
  font-size: 16px;
  font-weight: bold;
}

.number {
  @include color(takeda-dark-gray);
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
}


.paragraph {
  @include margin(2);
  font-size: 28px;
  @include responsive(phone){
    @include margin(0);
    margin-bottom: 20px;
    font-size: 20px;
  }
}

.triangle-img {
  padding-left: 15px;
}

</style>
